<template>
    <div class="footer">
        <div class="footers">
        <div class="footer-fl-titler">
            <h1>中共彭州市委外事工作委员会办公室 </h1>
            <h2>Office of the Foreign Affairs Commission of Pengzhou Municipal </h2>
        </div>


        <div class="footer-container">
            <div class="fl">

                <div class="phone">咨询电话：</div>
                <div class="phone-num">{{ form.unitPhonne }}</div>

                <div class="text">
                    <span>参观地址：{{ form.unitAddress }}</span>
                    <span style="margin-left: 86px;">参观时间：{{ form.visitTime }}</span>
                </div>

                <div class="text" style="margin: 24px 0;">
                    <span>网址：{{ form.unitWeb }}</span>
                    <span style="margin-left: 110px;">邮编：{{ form.unitPostcode }} </span>
                    <span style="margin-left: 76px;">邮箱：{{ form.unitEmail }}</span>
                </div>
            </div>

            <div class="fr">
                <div class="fr-h2">获 取 更 多 最 新 信 息</div>

                <div class="qr-containter">
                    <div class="qr-item">
                        <img :src="form.qrcode1" alt="">

                        <span>投资彭州</span>
                    </div>
                    <div class="qr-item">
                        <img :src="form.qrcode2" alt="">

                        <span>彭州发布</span>
                    </div>
                    <div class="qr-item">
                        <img :src="form.qrcode3" alt="">

                        <span>品鉴彭州</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="filing">
            <span>
                {{ form.webCopyRight }}
            </span>
            <a class="filinglink" target="_blank" :href="form.webFillingsLink">{{ form.webFillings}}</a>


            <!-- <span>公安备案号:5101810241445号</span> -->
        </div>
        </div>



    </div>
</template>

<script>
import { listCopyright } from '@/api/index'

export default {

    data(){
        return{
            
           form:{
            // 咨询电话
                unitPhonne: undefined,
                // 参观地址
                unitAddress: undefined,
                // 网址
                unitWeb: undefined,
                // 邮编
                unitPostcode: undefined,
                // 邮箱
                unitEmail: undefined,
                // 版权信息
                webCopyRight: undefined,
                // 备案信息
                webFillings: undefined,
                // 备案跳转
                webFillingsLink: undefined,
                // 参观时间
                visitTime: undefined,
                // 投资彭州
                qrcode1: undefined,
                // 彭州发布
                qrcode2: undefined,
                // 品鉴彭州
                qrcode3: undefined

            }
        }
    },
    mounted(){
        this.getlistCopyright()
    },
    methods:{
        getlistCopyright(){
            listCopyright({
                pageNum: 1,
                pageSize: 1
            }).then(res => {
                console.log(res);

                if (res.rows.length > 0) {
                    this.form = res.rows[0]
                }

                // this.bannerList = res.rows
            })
        },

    }

}
</script>

<style lang="less">
.footer {

    padding: 80px 120px;
    background-color: #e4e4ec;
    .footers{
        max-width:1450px;
        margin: auto;
    }

    .footer-fl-titler {
        h1 {
            font-size: 31px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #13141A;
            margin: 0;
        }

        h2 {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #13141A;
        }
    }

    .footer-container {
        display: flex;
        justify-content: space-between;

        border-bottom: 2px solid;

        .fl {
            .phone {
                margin-top: 45px;
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #13141A;
            }

            .phone-num {
                margin: 20px 0;
                font-size: 31px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #13141A;
            }

            .text {
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #13141A;
            }
        }



        .fr {
            .fr-h2 {
                font-size: 20px;
                letter-spacing: 0.2em;
                text-align: center;
            }

            .qr-containter {
                display: flex;
                margin-top: 20px;

                .qr-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 0 10px;

                    img {
                        width: 130px;
                        height: 130px;
                    }

                    span {
                        margin-top: 10px;
                    }

                }
            }
        }

    }

    .filing{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 16px;
        .filinglink{
            cursor: pointer;
            color: #13141A;
            text-decoration: none;
        }
    }
}
</style>