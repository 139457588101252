<template>
  <div class="jinpeng">
    <!-- 轮播图 -->
    <!-- slideshow -->
    <div style="padding-bottom: 30px;background-color: #edeef2;" v-if="bannerList.length > 0">
      <slideshow :backgroundColor="'#edeef2'" :bannerList="bannerList"></slideshow>
    </div>

    <!-- 导航栏 -->
    <div class="nav-bar">
      <div class="bar-iten" :class="item.id == navIndex ? 'activate-nav' : ''" v-for=" item in jinpengList" :key="item.id"  @click="navClick(item)">{{ item.title }}</div>
      <!-- <div class="bar-iten" @click="navClick(2)" :class="navIndex == 2 ? 'activate-nav' : ''">彭州印象</div>
      <div class="bar-iten" @click="navClick(3)" :class="navIndex == 3 ? 'activate-nav' : ''">历史文化</div>
      <div class="bar-iten" @click="navClick(4)" :class="navIndex == 4 ? 'activate-nav' : ''">产业发展</div>
      <div class="bar-iten" @click="navClick(5)" :class="navIndex == 5 ? 'activate-nav' : ''">国际交流</div> -->
    </div>


    <div class="rich-contact">
      <div class="rich-html">
        <div v-html="jinpengHtml" :class="['quill', 'ql-editor']"> </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Slideshow from '@/components/slideshow.vue';
import { listBanner } from '@/api/banner'
import { listJinpeng } from '@/api/jinpeng'
export default {
  components: {
    Slideshow

  },
  data() {
    return {
      navIndex: 1,
      bannerList: [],

      jinpengList:[],
      jinpengHtml:'',
      // 表单参数
      form: {
        biz: 'jinpeng',
        type: localStorage.getItem('languageSet') || 'zh',
        status: 1
      },

      jinpenForm: {
        type: localStorage.getItem('languageSet') || 'zh'
      }
    }
  },
  mounted() {
    this.getListBanner()
    this.getListJinpeng()
  },
  methods: {
    navClick(item) {
      this.navIndex = item.id

      this.jinpengHtml = item.content
    },

    getListBanner() {
      listBanner(this.form).then(res => {
        console.log(res);

        this.bannerList = res.rows
      })
    },

    getListJinpeng() {
      listJinpeng(this.jinpenForm).then(res => {
        console.log(res);
        this.jinpengList = res.rows

        if(res.rows.length > 0) this.navClick(res.rows[0])
      })
    }
  }

}
</script>
  
<style lang="less">
.jinpeng {
  
  .nav-bar {
    display: flex;
    height: 100px;
    background: #FFFFFF;
    padding: 0 120px;
    max-width:1450px;
        margin: auto;

    .activate-nav {
      background: #B3B3B3;
      color: #FFFFFF !important;
    }

    .bar-iten {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2F2F2F;
      padding: 0 30px;
      cursor: pointer;
    }
  }

  .rich-contact {
    padding: 60px 120px;
    background: #edeef2;
    

    .rich-html {
      background: #FFFFFF;
      // padding: 60px;
      max-width:1450px;
        margin: auto;

      border-radius: 30px;
      // height: 600px;

      img{
        max-width: 100%;
      }
    }
  }
  
}

</style>

<style  lang="less" scoped>
/deep/ .quill{
  padding:60px!important;
}
</style>