module.exports = {

  index: {
    nav: {
      nav1: 'Home Page',
      nav2: 'Tianfu Jinpeng',
      nav3: 'International Friendship City',
      nav4: 'Show Room',
      nav5: 'News'
    }

  },
  indexTitle:{
    city:"Pengzhou City Friendship (Cooperation) Relationship City",
    city1:'Friendly City',
    city2:'Friendly And Cooperative City',
    exhibition:'Show Room'
  },
  news:{
    title:'NEWS'
  }


}