import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);


Vue.config.productionTip = false



import VueI18n from 'vue-i18n'
//通过 Vue.use() 明确地安装 vue-i18n： 
Vue.use(VueI18n)

//增加代码准备翻译的语言环境，及配置文件路径CN/EN
const i18n = new VueI18n({
  locale:localStorage.getItem('languageSet')||'zh',
  messages:{
    'zh':require('./locale/lang/cn'),
    'en':require('./locale/lang/en'),
  }
})




new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
