<template>
    <div class="index-content">
        <!-- 轮播图 -->
        <!-- slideshow -->
        <div style="padding-bottom: 30px;" v-if="bannerList.length > 0">
            <slideshow :bannerList="bannerList"></slideshow>
        </div>


        <div class="friendly-city" id="friendlyCity">
            <h1 class="city-h1"> {{$t('indexTitle.city') }}</h1>
            <h2 class="city-h2">{{$t('indexTitle.city1') }}</h2>

            <div class="city-content" style="">

                <div class="frow">
                    <div class="city-item" v-for="item in imageSize0List" :key="item.id" @click="goInternational(item)">
                        <img :src="item.image" alt="">

                        <!-- 文本 -->
                        <div class="city-text">
                            <div style="">
                                <span>{{ item.name }}</span>
                            </div>
                            <div>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                    <!--                     
                    <div class="city-item">
                        <img src="../assets/city/02.png" alt="">
                        <div class="city-text">
                            <div style="">
                                <span>日本 / 石狩市</span>
                            </div>
                            <div>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div> -->

                </div>

                <div class="frow" style="margin-top: 16px;">

                    <div class="city-item2" v-for="item in imageSize1List" :key="item.id"  @click="goInternational(item)">
                        <img :src="item.image" alt="">
                        <div class="city-text">
                            <div style="">
                                <span>{{ item.name }}</span>
                            </div>
                            <div>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="city-item2">
                        <img src="./../assets/city/10.png" alt="">
                        <div class="city-text">
                            <div style="">
                                <span>法国 / 塞纳-马恩省 </span>
                            </div>
                            <div>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div> -->

                </div>

            </div>


            <h2 class="city-h2" style="margin-top: 72px;">{{$t('indexTitle.city2') }}</h2>


            <div class="cooperation">
                <!-- :loop="false" -->
                <el-carousel :autoplay="false" :interval="5000" arrow="always" height="910px" indicator-position="none">
                    <el-carousel-item v-for="(item,index) in imageRelation" :key="index">
                        <!-- <h3>{{ item }}</h3> -->
                        <div class="relation-flex">
                            <div class="relation" v-for="relation in item" :key="relation.id"  @click="goInternational(relation)">
                                <img :src="relation.image" alt="">
                                <div class="city-text">
                                    <div style="">
                                        <span>{{ relation.name }}</span>
                                    </div>
                                    <div>
                                        <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>


        </div>


        <div class="exhibition">
            <h1 class="exhibition-h1">{{$t('indexTitle.exhibition') }}</h1>

            <div class="exhibition-big">
                <!-- <img src="./../assets/city/03.png" alt=""> -->
                <el-carousel arrow="always" :autoplay="false" height="579px" :interval="8000">
                    <el-carousel-item v-for="item in index2List" :key="item.id">
                        <img class="slide-img" :src="item.image" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>


            <div class="exhibition-img">

                <div class="img-div" @click="goExhibition(0)">
                    <img :src="smallImage1" alt="">
                </div>

                <div class="img-div"  @click="goExhibition(1)">
                    <img :src="smallImage2" alt="">
                </div>

                <div class="img-div"  @click="goExhibition(2)">
                    <img :src="smallImage3" alt="">
                </div>
            </div>
        </div>


        <div class="promotional" @click="dialogVisible = true">
            <img src="./../assets/city/04.png" alt="">
        </div>



        <el-dialog class="index-dialog" :visible.sync="dialogVisible" width="70%" :showClose="false" :before-close="handleClose">
            <!-- <span>这是一段信息</span> -->

            <video style="width: 100%;" ref="videoRef" :src="indexVideo" class="video-avatar" :autoplay="false"
                controls="controls" muted loop></video>

        </el-dialog>

    </div>
</template>

<script>
import NavigationBar from "@/components/navigationBar.vue";
import Slideshow from '@/components/slideshow.vue';
import { listBanner } from '@/api/banner'
import { listIndex, listCity } from '@/api/index'

export default {
    components: {
        NavigationBar,
        Slideshow
    },

    data() {
        return {
            bannerList: [],
            index2List: [],
            // 小图
            imageSize0List: [],
            // 大图
            imageSize1List: [],
            // 友好合作城市
            imageRelation: [],
            // 表单参数
            form: {
                biz: 'index',
                type: localStorage.getItem('languageSet') || 'zh',
                status: 1
            },
            smallImage1: "",
            smallImage2: "",
            smallImage3: "",
            indexVideo: "",
            dialogVisible: false,

            // indexForm
        }
    },
    mounted() {

        this.getListBanner()
        this.getListIndex()
        this.getListIndex2()

        // 小图
        this.getImageSize0()
        // 大图
        this.getImageSize1()

        this.getImageRelation()


        if (this.$route.query.menu == 3) {

            console.log(document.querySelector('#friendlyCity'));

            let city = document.querySelector('#friendlyCity')
            if (city) city.scrollIntoView(true)
        }
    },
    methods: {

        goExhibition(index){
            this.$router.push({path:'/exhibition',query:{exhmenu:index}})
        },

        goInternational(item){
            localStorage.setItem("internationalStorage",item.content)
            this.$router.push("/international")

        },

        handleClose(done) {

            console.log("----");
            // console.log(this.$refs.videoRef);

            this.$refs.videoRef.pause()

            this.dialogVisible = false
        },
        //  relation 1-友好城市；2-友好合作城市 imageSize 0小图 1大图
        getImageSize0() {
            listCity({
                pageNum: 1,
                pageSize: 3,
                relation: 1,
                imageSize: 0,
                type: localStorage.getItem('languageSet') || 'zh',
            }).then(res => {
                this.imageSize0List = res.rows

                console.log(res);
            })
        },

        getImageSize1() {
            listCity({
                pageNum: 1,
                pageSize: 2,
                relation: 1,
                imageSize: 1,
                type: localStorage.getItem('languageSet') || 'zh',
            }).then(res => {
                console.log("imageSize1List", res.rows);
                this.imageSize1List = res.rows
            })
        },
        getImageRelation() {
            listCity({
                pageNum: 1,
                pageSize: 18,
                relation: 2,
                type: localStorage.getItem('languageSet') || 'zh',
            }).then(res => {
                // [[],[]]
                // this.imageRelation = res.rows

                console.log("imageRelation", res);

                this.imageRelation = this.oneArrToTwoArr(res.rows)
                console.log("imageRelation1", this.imageRelation);
            })
        },

        // 一维数组转二维数组
        oneArrToTwoArr(data) {
            var newData = [];
            var zyf = 6;  //一维数组转二维数组长度(此处是二维数组每一个长度控制)
            for (var i = 0; i < Math.ceil(data.length / zyf); i++) {
                newData[i] = [];
                newData[i].push(data[i * zyf]);
                for (var j = 1; j < zyf; j++) {
                    if (data[i * zyf + j] == undefined) { //超出长度控住
                        return newData;
                    } else {
                        newData[i].push(data[i * zyf + j]);
                    }

                }
            }
            return newData;
        },

        getListIndex() {
            listIndex({
                type: localStorage.getItem('languageSet') || 'zh',
                pageNum: 1,
                pageSize: 10
            }).then(res => {
                // console.log(res);

                if (res.rows.length > 0) {
                    let data = res.rows[0]
                    this.smallImage1 = data.smallImage1
                    this.smallImage2 = data.smallImage2
                    this.smallImage3 = data.smallImage3
                    this.indexVideo = data.video
                    // console.log(this.indexVideo);
                }

                // this.bannerList = res.rows
            })
        },


        getListBanner() {
            listBanner(this.form).then(res => {
                // console.log(res);

                this.bannerList = res.rows

            })
        },

        // 首页展馆实景：index2
        getListIndex2() {

            this.form.biz = 'index2'

            listBanner(this.form).then(res => {
                // console.log(res);

                this.index2List = res.rows

            })
        }

    },
    // beforeRouteEnter(to, from, next) {
    //     console.log("===");
    //     next()

    //     console.log(document.querySelector('#friendlyCity'));
    // },
}
</script>

<style lang="less">


.index-dialog .el-dialog__body{
    padding: 0;
}

.index-dialog .el-dialog__header{
    padding: 0;
}

// friendly-city
// 彭州市友好 (合作) 关系城市

.friendly-city {
    padding: 0 120px;
    background-color: #edeef2;
    


    .city-h1 {
        font-size: 40px;
        font-weight: bold;
        color: #1A1A1A;
        padding-bottom: 34px;
        padding-top: 76px;
        max-width:1450px;
        margin: auto;

    }

    .city-h2 {
        font-size: 31px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1A1A1A;
        max-width:1450px;
        margin: auto;
        padding-bottom: 52px;
       

    }



}



.city-content {

    .frow {
        display: flex;
        max-width:1450px;
        margin:auto;
        justify-content: space-between;
        flex-wrap: wrap;

        .city-item {
            position: relative;
            width: 32.33%;
            height: 440px;
            border-radius: 20px;
            overflow: hidden;
            // margin-right: 20px;
            cursor: pointer;

            img {
                // width: 100%;
                width: 570px;
                // height: 100%;
                transition: all .3s ease-in;
            }

            &:hover img {
                transform: scale(1.1);
            }

        }


        .city-item2 {
            position: relative;
            width: 49%;
            height: 440px;
            // margin-right: 20px;
            border-radius: 20px;
            overflow: hidden;
            cursor: pointer;

            img {
                width: 865px;
                transition: all .3s ease-in;
                // width: 100%;
                // height: 100%;
            }

            &:hover img {
                transform: scale(1.1);
            }

        }



        .city-text {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 74px;
            width: 100%;
            color: #FFFFFF;
            font-size: 28px;
            background: #A2BD3C;

            div {
                padding:0 20px;
            }
        }
    }


}




// edeef2
// b2b2ba
// e4e4ec

/deep/ .el-carousel__container {
    height: 600px;
    max-width:1450px;
    margin:auto;
}
.el-carousel--horizontal {
    overflow-x: hidden;
    max-width: 1450px;
    margin: auto;
}

.el-carousel__item {
    // background-color: #99a9bf;
    //background-color: #edeef2;
    

    .relation-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        // width: 120%;
        .relation {
            position: relative;
            width: 32.33%;
            height: 400px;
            //margin-right: 20px;
            margin-bottom: 16px;
            // background: #404040;
            border-radius: 20px;
            overflow: hidden;
            cursor: pointer;

            img {
                width: 570px;
                transition: all .3s ease-in;
                // width: 100%;
                // height: 100%;
            }


            &:hover img {
                transform: scale(1.1);
            }

            .city-text {
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 74px;
                width: 100%;
                color: #FFFFFF;
                font-size: 28px;

                background: #E8C219;

                div {
                    padding:0 20px;
                }
            }
        }

        .relation:nth-of-type(3n) {
            margin-right: 0;
        }

    }


}

.cooperation .el-carousel__arrow {
    background-color: green;
    width: 64px;
    height: 64px;
    border-radius: 0;
    background: #B2B2BA;
    top: 95%;
    font-size: 26px;
    // bottom: 10px;
}

.cooperation .el-carousel__arrow--right {
    left: 86px;
}



.exhibition {
    padding: 0 120px;
    max-width: 1450px;
    margin: auto;

    .exhibition-h1 {
        margin: 60px 0;
        font-size: 40px;
        font-weight: bold;
        color: #0A0A0D;
    }

    .exhibition-big {
        height: 579px;
        border-radius: 20px;
        overflow: hidden;

        img {
            width: 1450px;
            transition: all .3s ease-in;
            // width: 100%;
            // height: 100%;
        }

        // &:hover img {
        //     transform: scale(1.1);
        // }
    }

    .exhibition-img {

        display: flex;
        justify-content: space-between;

        max-width:1450px;
        margin:20px auto 0;

        .img-div {
            position: relative;
            width: 576px;
            height: 410px;
            border-radius: 20px;
            margin-left: 8px;
            cursor: pointer;
            overflow: hidden;

            img {
                width: 100%;
                transition: all .3s ease-in;
                height: 410px;
                // width: 100%;
                // height: 100%;
            }

            &:hover img {
                transform: scale(1.1);
            }

            .img-bg {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 0;
                // width: 100%;
                // height: 100%;
                width: 576px;
                height: 410px;
                border-radius: 20px;
                background: #93a73f;
                opacity: 0.6;

                // background-color: rgba(154, 155, 50, -10);

                .img-text {
                    font-size: 40px;
                    padding: 16px 50px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }

                i {
                    margin-top: 20px;
                    font-size: 40px;
                    color: #FFFFFF;
                }
            }
        }

    }

}


.promotional {
    margin: 68px auto;
    padding: 0 120px;
    height: 327px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    max-width:1450px;


    img {
        width: 100%;
        // height: 100%;
    }
}


.exhibition-big {
    // padding: 0 120px;

    .el-carousel__container {
        border-radius: 30px;
        overflow: hidden;
        // background-color: red;

    }

    .el-carousel__item {
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: #FFFFFF;
    }


    .el-carousel__arrow {
        width: 64px;
        height: 64px;

        font-size: 26px;
        // bottom: 10px;
    }



    .slide-img {

        // width: 100%;
        width: 1450px;
        // height: 100%;


    }
}
</style>