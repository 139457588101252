<template>
    <div class="new-deatils">
        <div class="new-list">
            <div class="newdfl">
                <div class="newbg">
                    <div class="new-text">
                        <div>{{ detail.dates }}</div>
                        <div style="margin-top: 10px;">新闻动态</div>
                    </div>
                </div>
            </div>
            <div class="newdfr">
                <div style="padding-right: 20px;"  v-html="detail.content" :class="['quill', 'ql-editor']"></div>
            </div>
        </div>
    </div>
</template>

<script>

import { getNews } from "@/api/news";
export default {
    data() {
        return {
            detail: ""
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            console.log(this.$route.query.id);
            //   this.content =  localStorage.getItem(deatil)

            getNews(this.$route.query.id).then(res => {
                console.log(res);

                this.detail = res.data
            })
        }
    }
}
</script>

<style lang="less">
.new-deatils {
    padding: 10px 64px 60px;
    background-color: #B2B2BA;

    .new-list {
        display: flex;
        min-height: 960px;
        background-color: #fff;
        max-width:1450px;
        margin: auto;

        .newdfl {
            width: 800px;
            height: 800px;
            padding-top: 60px;
            .newbg {
                height: 860px;
                background-repeat: no-repeat;
                // background-position: 0px 26px;
                background-size: contain;
                background-image: url(../assets/shade.png);

                .new-text {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #232323;
                    margin-left: 200px;
                    // margin-top: 60px;
                }
            }
        }



        .newdfr {
            margin-top: 60px;
            padding-right: 20px;
            flex-shrink: 0;
            width: 50%;

            img {
                max-width: 100%;
            }
        }
    }


}
</style>