import request from '@/utils/request'

// 查询公告列表
export function listNews(query) {
    return request({
      url: '/system/news/list',
      method: 'get',
      params: query
    })
  }
  

  // 查询公告详细
export function getNews(newsId) {
  return request({
    url: '/system/news/' + newsId,
    method: 'get'
  })
}