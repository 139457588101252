<template>
  <div class="news-pages">
    <div class="news-contact">

      <div class='nei'>

      <div class="news-title">
        <span class="cn">{{$t('news.title') }}</span>
        <!-- <span class="en">新闻动态</span> -->
      </div>


      <div class="new-item" v-for="(item, index) in newsList" :key="index" @click="goDetail(item)">
        <div class="fl">
          <img :src="item.image" alt="">
        </div>
        <div class="fr">
          <div class="time">{{ item.dates }}</div>
          <h3 class="title">{{ item.title }} </h3>
          <p class="describe">{{ item.subTitle }}</p>

          <div class="decoration">{{ item.unitName }}</div>
        </div>
      </div>

      <div class="pages-contact">
        <el-pagination :page-size="queryParams.pageSize" @current-change="handleSizeChange" :pager-count="11"
          layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>

      </div>
      
    </div>



  </div>
</template>

<script>


import { listNews } from "@/api/news";
export default {
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: localStorage.getItem('languageSet') || 'zh',
        newsTitle: undefined,
        createBy: undefined,
        status: undefined
      },
      newsList: [],
      total: 0
    }
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);

      this.queryParams.pageNum = val
      this.getList()
    },
    /** 查询公告列表 */
    getList() {
      this.loading = true;
      listNews(this.queryParams).then(response => {
        this.newsList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    goDetail(item) {
      console.log(item.id);
      this.$router.push({ path: "/newdetails", query: { id: item.id } })
    }
  }

}
</script>

<style lang="less">
.news-pages {
  background: #edeef2;
  padding: 6px 120px 0;

  .news-contact {
    padding: 60px 0;

    background-color: #fff;
    max-width:1450px;
        margin: auto;


    .news-title {
      display: flex;
      align-items: center;

      .cn {
        font-size: 40px;
        font-family: Helvetica;
        font-weight: bold;
        color: #4D4D4D;
      }

      .en {
        font-size: 120px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4D4D4D;
        margin-left: 36px;
        margin-top: -9px;
      }
    }

    .new-item {
      display: flex;
      padding: 52px 0;
      border-bottom: 1px solid #C3C3C3;
      cursor: pointer;


      .fl {
        // flex: 1;
        flex-shrink: 0;
        width: 322px;
        // height: 320px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .fr {
        margin-left: 62px;

        .time {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #191919;
        }

        .title {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #191919;
        }

        .describe {
          font-size: 18px;
          color: #6F6F6F;

        }

        .decoration {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-decoration: underline;
          color: #6F6F6F;
        }
      }
    }
  }

  .pages-contact {
    width: 100%;
    text-align: center;
    padding-top: 60px;

  }
}
.nei{
  padding:0 60px
}
</style>

