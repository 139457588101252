import request from '@/utils/request'

// 查询视频
export function listIndex(query) {
    return request({
        url: '/system/index/list',
        method: 'get',
        params: query
    })
}

// 查询参数列表
export function listCopyright(query) {
    return request({
      url: '/system/unit/list',
      method: 'get',
      params: query
    })
  }

  // 查询公告列表
export function listCity(query) {
    return request({
      url: '/system/city/list',
      method: 'get',
      params: query
    })
  }
  