<template>
    <div class="exhibition-contact">
        <!-- 轮播图 -->
        <!-- slideshow -->
        <div style="padding-bottom: 84px;background-color: #edeef2;" v-if="bannerList.length > 0">
            <slideshow :backgroundColor="'#edeef2'" :bannerList="bannerList"></slideshow>
        </div>

        <!-- 导航栏 -->
        <div class="nav-bar" id="navBarEx">
            <div class="bar-iten" @click="navClick(item)" :class="navIndex == item.id ? 'activate-nav' : ''"
                v-for=" item in exhibitionList" :key="item.id">{{ item.title }}</div>
            <!-- <div class="bar-iten" @click="navClick(1)" :class="navIndex == 1 ? 'activate-nav' : ''">AR互动体验</div>
            <div class="bar-iten" @click="navClick(2)" :class="navIndex == 2 ? 'activate-nav' : ''">VR互动体验</div>
            <div class="bar-iten" @click="navClick(3)" :class="navIndex == 3 ? 'activate-nav' : ''">球幕互动体验</div> -->
        </div>


        <div class="rich-contact">
            <div class="video-html">
                <div class="video-contact" v-if="videoUrl">
                    <!-- <video preload="metadata" crossorigin="anonymous" :src="videoUrl"></video> -->
                    <video style="width: 100%;height: 100%;" :src="videoUrl" class="video-avatar" :autoplay="false"
                        controls="controls" muted loop></video>
                </div>
                <div class="html-contact ">
                    <div v-html="exhibitionHtml" :class="['quill', 'ql-editor']"></div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Slideshow from '@/components/slideshow.vue';
import { listBanner } from '@/api/banner'

import { listExhibition } from '@/api/exhibition'
export default {
    components: {
        Slideshow

    },
    data() {
        return {
            navIndex: 1,
            bannerList: [],
            exhibitionList: [],
            videoUrl: '',
            exhibitionHtml: '',
            // 表单参数
            form: {
                biz: 'exhibition',
                type: localStorage.getItem('languageSet') || 'zh',
                status: 1
            },

            exhibitionForm: {
                type: localStorage.getItem('languageSet') || 'zh'
            }
        }
    },
    mounted() {
        this.getListBanner()
       
    },
    methods: {
        navClick(item) {
            this.navIndex = item.id
            this.videoUrl = item.video
            this.exhibitionHtml = item.content
        },
        getListBanner() {
            listBanner(this.form).then(res => {
                console.log(res);

                this.bannerList = res.rows

                this.getExhibition()
            })
        },
        getExhibition() {


            listExhibition(this.exhibitionForm).then(res => {
                console.log(res);
                this.exhibitionList = res.rows
                let exhmenu = this.$route.query.exhmenu
                if (exhmenu === undefined) exhmenu = 0


                if (res.rows.length > 0) this.navClick(res.rows[exhmenu])

                let city = document.querySelector('#navBarEx')
                console.log(city);
                if (city) city.scrollIntoView(true)
                window.scrollBy(0, -100);
                // this.bannerList = res.rows


                console.log(this.$route.query.exhmenu);
            })
        }
    }
}
</script>
  
<style lang="less">
.exhibition-contact {
    .nav-bar {
        display: flex;
        height: 100px;
        background: #FFFFFF;
        padding: 0px 120px;
        max-width:1450px;
        margin: auto;

        .activate-nav {
            background: #B3B3B3;
            color: #FFFFFF !important;
        }

        .bar-iten {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 19px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2F2F2F;
            padding: 0 30px;
            cursor: pointer;
        }
    }

    .rich-contact {
        padding: 60px 120px;
        background: #edeef2;
        

        .video-html {
            background: #FFFFFF;
            padding: 30px;
            border-radius: 30px;
            max-width:1450px;
        margin: auto;

            // height: 600px;
            .video-contact {
                height: 600px;
                background-color: #000;
            }

            .html-contact {
                padding-top: 140px;

                img {
                    max-width: 100%;
                }
            }
        }
    }
}
</style>