module.exports = {



  index: {
    nav: {
      nav1: '首页',
      nav2: '天府金彭',
      nav3: '国际友城',
      nav4: '展馆实景',
      nav5: '新闻动态'
    },

  },
  indexTitle:{

    city:"彭州市友好 (合作) 关系城市",
    city1:'友好城市',
    city2:'友好合作关系城市',
    exhibition:'展馆实景'
  },
  news:{
    title:'新闻动态'
  }

}