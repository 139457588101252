import { render, staticRenderFns } from "./jinpeng.vue?vue&type=template&id=0df0d046&scoped=true&"
import script from "./jinpeng.vue?vue&type=script&lang=js&"
export * from "./jinpeng.vue?vue&type=script&lang=js&"
import style0 from "./jinpeng.vue?vue&type=style&index=0&id=0df0d046&prod&lang=less&"
import style1 from "./jinpeng.vue?vue&type=style&index=1&id=0df0d046&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0df0d046",
  null
  
)

export default component.exports