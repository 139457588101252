<template>
  <div class="international">
    <!-- 轮播图 -->
    <!-- slideshow -->
    <!-- <div style="padding-bottom: 84px;">
      <slideshow></slideshow>
    </div> -->


    <div class="rich-contact">
      <div v-html="content" :class="['quill', 'ql-editor']"></div>
    </div>
  </div>
</template>
  
<script>
import Slideshow from '@/components/slideshow.vue';
export default {
  components: {
    Slideshow

  },
  data() {
    return {
      content: localStorage.getItem("internationalStorage")
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
  
<style lang="less">
.international {
  .rich-contact {
    padding: 60px 120px;
    background: #FFFFFF;
    max-width:1450px;
        margin: auto;

    img{
      max-width: 100%;
    }

    div {
      background: #FFFFFF;

      border-radius: 30px;
      // height: 600px;
    }
  }
}
</style>